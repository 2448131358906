import React, { createContext, useContext, useState } from "react";

const ThemeContext = createContext();

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light-theme");
  const [isToggled, setIsToggled] = useState(true);

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === "light-theme" ? "dark-theme" : "light-theme"
    );
    setIsToggled(!isToggled);
  };

  return (
    <ThemeContext.Provider
      value={{ theme, toggleTheme, isToggled, setIsToggled }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
