import React, { useEffect } from "react";
import Navigation from "../Home/Navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRightShort } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Footer from "../Home/Footer";
import { useLanguage } from "../../LanguageContext";

export default function AVLibrary() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { language } = useLanguage();

  const homeText = language === "es" ? "Inicio" : "Home";
  const portfolioText = language === "es" ? "Portafolio" : "Case Studies";
  const overviewTitle = language === "es" ? "Resumen" : "Overview";
  const designProcess =
    language === "es" ? "Proceso de diseño" : "Design process";
  const researchTitle = language === "es" ? "1. Investigación" : "1. Research";
  const prototypingTitle =
    language === "es" ? "2. Prototipado" : "2. Prototyping";
  const developmentTitle =
    language === "es" ? "3. Desarrollo" : "3. Development";
  const deploymentTitle = language === "es" ? "4. Despliegue" : "4. Deployment";
  const seeItLiveText = language === "es" ? "Ver proyecto" : "See it live"
  const nextProject = language === "es" ? "Siguiente proyecto" : "Next project"

  const title =
    language === "es"
      ? "Librería UI para AudienceView"
      : "AudienceView's UI Library";

  const headerDescription =
    language === "es"
      ? "Desarrollo de Sistema de Diseño en Storybook con componentes diseñados en Figma"
      : "Development of Design System in Storybook with components designed in Figma";

  const overviewText1 =
    language === "es"
      ? "Librería UI para React Native con el sistema de diseño de AudienceView. Su objetivo es proporcionar a los desarrolladores componentes preestablecidos y estilos definidos para agilizar la creación de interfaces atractivas. Basada en React Native Paper, ofrece elementos como botones, cards y elementos de formulario, garantizando la coherencia visual."
      : "React Native UI Library with the AudienceView's Design System. It aims to provide developers with preset components and consistent styles to streamline the creation of attractive interfaces. Based on React Native Paper, it offers elements such as buttons, cards and form elements, ensuring visual consistency.";

  const overviewText2 =
    language === "es"
      ? "La principal ventaja es que acelera el desarrollo proporcionando una base sólida de componentes estilizados, mejorando la eficiencia y la coherencia en la experiencia del usuario final. La adopción de esta biblioteca permite a los equipos centrarse en la funcionalidad sus aplicaciones, mejorando la calidad visual de forma eficiente."
      : "The key advantage is to accelerate development by providing a solid foundation of styled components, improving efficiency and consistency in the end-user experience. Adopting this library allows teams to focus on the core functionality of their applications, improving visual quality in an efficient manner.";

  const researchText =
    language === "es"
      ? "Aunque había trabajado con React, no había tenido la oportunidad de trabajar con React Native así que decidí investigar sobre frameworks que fueran confiables y que pudiéramos implementar sin conflictos en los proyectos en curso, así que junto con el equipo de desarrollo decidimos que implementaríamos React Native Paper."
      : "Although I had worked with React, I haven't had the opportunity to work with React Native so I decided to do some research on frameworks that were reliable and that we could implement without conflicts in the ongoing projects, so together with the development team we decided that we would implement React Native Paper.";

  const prototypingText =
    language === "es"
      ? "Los prototipos ya estaban construidos, ya que tomamos los componentes de pantallas que ya estaban diseñadas, pero creamos un archivo Figma organizado con todos los componentes que necesitábamos en la librería."
      : "The prototypes were already built as we took the components from screens that were already designed, but we created an organized Figma file with all the components we needed in the library.";

  const developmentText =
    language === "es"
      ? "Desarrollamos y compilamos una primera versión de la librería con sólo dos componentes, y creamos un paquete NPM que nos permitía instalarla en los proyectos que ya estaban en desarrollo para probar que todo funcionaba sin conflictos. Después hicimos cambios visuales en los componentes de la librería y actualizamos la versión para probar el proceso de actualización, y finalmente construimos el resto de componentes."
      : "We developed and compiled a first version of the library with only two components, and created an NPM package that allowed us to install it into the projects that were already running to test that everything worked without conflicts. We then made visual changes to the library components and updated the version to test the update process, and finally we built the remaining components.";

      const deploymentText =
    language === "es"
      ? "El despliegue de la librería fue sencillo ya que creamos un paquete NPM privado que sólo tuvimos que instalar en los proyectos para empezar a utilizar la librería."
      : "The deployment of the library was simple since we created a private NPM package which we only had to install in the projects to start using the library.";

      const nextProjectName = language === "es" ? "App de empleados Latam Airlines" : "Latam Airlines Employees App"
  return (
    <>
      <Navigation />
      <section id="project-header" className="color-change-4x">
        <Container fluid className="default-container">
          <Row className="hero-project justify-content-center">
            <Col className="col-default">
              <Row>
                <Col md={12}>
                  <div className="project--title">
                    <h1>{title}</h1>
                    <h2>{headerDescription}</h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid className="default-container">
          <Row>
            <Col className="project-container">
              <div className="breadcrumb">
                <Link to="/" className="breadcrumb--item">
                  {homeText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <Link to="/#portfolio" className="breadcrumb--item">
                  {portfolioText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <div className="breadcrumb--item active">{title}</div>
              </div>

              <div className="overview">
                <h3 className="title">{overviewTitle}</h3>
                <p>{overviewText1}</p>
                <p className="mb-5">{overviewText2}</p>
              </div>
              <div className="img-container">
                <img
                  className="w-100"
                  src="./img/projects/av-ui-library-01.png"
                />
                <img
                  className="w-100 mt-5"
                  src="./img/projects/av-ui-library-02.png"
                />
                <img
                  className="w-100 mt-5"
                  src="./img/projects/av-ui-library-03.png"
                />
              </div>

              <div className="overview pt-5">
                <h3 className="title">{designProcess}</h3>
                <h4>{researchTitle}</h4>
                <p>{researchText}</p>

                <h4>{prototypingTitle}</h4>
                <p>{prototypingText}</p>

                <h4>{developmentTitle}</h4>
                <p>
                  {developmentText}
                </p>

                <h4>{deploymentTitle}</h4>
                <p>
                  {deploymentText}
                </p>
              </div>

              <div className="button-container">
                <Button
                  href="https://dev--64fa17529bd7d33e853df05f.chromatic.com/?path=/docs/av-ui-library--docs"
                  className="btn-ppal"
                  target="_blank"
                >
                  {seeItLiveText} <ArrowRightShort size={24} />
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="next-project">
            <Col>
              <h3 className="title">{nextProject}</h3>
              <Link className="link" to="/latam-airlines-app">
                {nextProjectName}
                <ArrowRightShort size={64} />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
