import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ArrowRightShort } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useTheme } from "../../ThemeContext";
import "@theme-toggles/react/css/Expand.css";
import { Expand } from "@theme-toggles/react";
import FlagSwitcher from "../FlagSwitcher";
import { useLanguage } from "../../LanguageContext";

export default function Navigation() {
  const { language } = useLanguage();
  const { theme, toggleTheme, isToggled, setIsToggled } = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleNavLinkClick = () => {
    setMenuOpen(false);
  };

  const handleExpandToggle = () => {
    toggleTheme();
    handleNavLinkClick();
  };

  const portfolio = language === "es" ? "Portafolio" : "Case Studies";
  const aboutMe = language === "es" ? "Acerca de mi" : "About me";
  const contact = language === "es" ? "Contacto" : "Contact";

  return (
    <Navbar expand="md" fixed="top" className="navbar color-change-4x">
      <Container fluid className="navbar--container">
        <Navbar.Brand as={Link} to="/#home" className="navbrand">
          <img
            alt="Andrea"
            src="/img/logo.svg"
            width="34"
            height="34"
            className="logo d-inline-block"
          />{" "}
          Andrea
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls={`offcanvasNavbar-expand-md}`}
          onClick={handleToggleMenu}
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-md`}
          aria-labelledby={`offcanvasNavbarLabel-expand-md`}
          placement="end"
          show={menuOpen}
          className="color-change-4x"
        >
          <Offcanvas.Header onClick={handleToggleMenu} closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
              <Navbar.Brand className="navbrand" href="/#home">
                <img
                  alt="Andrea"
                  src="/img/logo.svg"
                  width="34"
                  height="34"
                  className="logo d-inline-block"
                />{" "}
                Andrea
              </Navbar.Brand>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1">
              <Nav.Link onClick={handleNavLinkClick} as={Link} to="/#about">
                {aboutMe}
              </Nav.Link>
              <Nav.Link onClick={handleNavLinkClick} as={Link} to="/#portfolio">
                {portfolio}
              </Nav.Link>
              <Nav.Link
                onClick={handleNavLinkClick}
                as={Link}
                className="nav-contact"
                to="/#contact"
              >
                {contact}{" "}
                <ArrowRightShort className="arrow-contact" size={24} />
              </Nav.Link>
              <Expand
                duration={500}
                toggled={isToggled}
                toggle={handleExpandToggle}
              />
              <FlagSwitcher />
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
