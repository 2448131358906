import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Contact from "../Contact";
import HeroSection from "../HeroSection";
import Navigation from "../Navigation";
import Portfolio from "../Portfolio";
import WhatIDo from "../WhatIDo";
import Footer from "../Footer";

export default function Home() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>
      <Navigation />
      <HeroSection />
      <WhatIDo />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
}
