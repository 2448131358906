import React, { useEffect } from "react";
import Navigation from "../Home/Navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRightShort } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Footer from "../Home/Footer";
import { useLanguage } from "../../LanguageContext";

export default function PaymentSystem() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { language } = useLanguage();

  const homeText = language === "es" ? "Inicio" : "Home";
  const portfolioText = language === "es" ? "Portafolio" : "Case Studies";
  const overviewTitle = language === "es" ? "Resumen" : "Overview";
  const designProcess =
    language === "es" ? "Proceso de diseño" : "Design process";
  const researchTitle = language === "es" ? "1. Investigación" : "1. Research";
  const prototypingTitle =
    language === "es" ? "2. Prototipado" : "2. Prototyping";
  const testingTitle = language === "es" ? "3. Testeo" : "3. Testing";
  const developmentTitle =
    language === "es" ? "4. Desarrollo" : "4. Development";
  const nextProject = language === "es" ? "Siguiente proyecto" : "Next project";
  const seePrototype = language === "es" ? "Ver prototipo" : "See prototype";

  const title =
    language === "es" ? "Sistema de Gestión de Pagos" : "Payment System";
  const headerDescription =
    language === "es"
      ? "Plataforma para facilitar la gestión de cobros y pagos a los clientes de Maxxa"
      : "Platform to facilitate the management of receipts and payments to Maxxa's customers";

  const overviewText1 =
    language === "es"
      ? "El objetivo de esta plataforma era digitalizar la gestión de los ejecutivos a la hora de generar, eliminar y renovar las órdenes de pago."
      : "The purpose of this platform was to digitize the management of executives when generating, eliminating and renewing payment orders.";

  const overviewText2 =
    language === "es"
      ? "Además, sería una solución para los clientes que querían pagar en línea."
      : "Additionally, it was a solution for customers who wanted to pay online.";

  const researchText =
    language === "es"
      ? "La recopilación de información se hizo mediante entrevistas directas con los ejecutivos, que serían los usuarios finales de la plataforma."
      : "The information gathering was done through interviews directly with the executives, who would be the end users of the platform.";

  const prototypeText01 =
    language === "es"
      ? "Se construyeron prototipos de baja fidelidad para la validación de la solución propuesta, y luego se desarrolló"
      : "Low-fidelity prototypes were developed for validation, and then a";
  const prototypeText02 =
    language === "es" ? "uno de alta fidelidad" : "high-fidelity one";
  const prototypeText03 =
    language === "es"
      ? "con el sistema de diseño de la empresa para hacerle el traspaso a los desarrolladores."
      : "was built with the company's design system for hand-off to developers.";

  const testingText =
    language === "es"
      ? "Se coordinaron tests de usabilidad con usuarios finales, tanto ejecutivos como clientes, para confirmar que la solución se ajustaba a sus necesidades."
      : "Usability testing sessions were conducted with end users, both executives and customers, to confirm that the solution was suitable for their needs.";

  const developmentText =
    language === "es"
      ? "El desarrollo final de la plataforma se realizó con React y Bootstrap."
      : "The final development of the platform was done with React and Bootstrap frameworks.";

  return (
    <>
      <Navigation/>
      <section id="project-header" className="color-change-4x">
        <Container fluid className="default-container">
          <Row className="hero-project justify-content-center">
            <Col className="col-default">
              <Row>
                <Col md={12}>
                  <div className="project--title">
                    <h1>{title}</h1>
                    <h2>{headerDescription}</h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid className="default-container">
          <Row>
            <Col className="project-container">
              <div className="breadcrumb">
                <Link to="/" className="breadcrumb--item">
                  {homeText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <Link to="/#portfolio" className="breadcrumb--item">
                  {portfolioText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <div className="breadcrumb--item active">{title}</div>
              </div>

              <div className="overview">
                <h3 className="title">{overviewTitle}</h3>
                <p>{overviewText1}</p>
                <p className="mb-5">{overviewText2}</p>
              </div>
              <div className="img-container">
                <img
                  className="w-100"
                  src="./img/projects/paymentsystem-01.png"
                />
                <img
                  className="w-100 mt-5"
                  src="./img/projects/paymentsystem-02.png"
                />
              </div>

              <div className="overview pt-5">
                <h3 className="title">{designProcess}</h3>
                <h4>{researchTitle}</h4>
                <p>{researchText}</p>

                <h4>{prototypingTitle}</h4>
                <p>
                  {prototypeText01}{" "}
                  <a
                    href="https://xd.adobe.com/view/fdb26ed0-6528-43a8-b465-ddbc6620e11b-a8e1/"
                    target="_blank"
                    className="link"
                  >
                    {prototypeText02}{" "}
                  </a>{" "}
                  {prototypeText03}
                </p>

                <h4>{testingTitle}</h4>
                <p>{testingText}</p>

                <h4>{developmentTitle}</h4>
                <p className="mb-5 pb-5">{developmentText}</p>
              </div>

              <div className="button-container">
                <Button
                  href="https://xd.adobe.com/view/fdb26ed0-6528-43a8-b465-ddbc6620e11b-a8e1/"
                  className="btn-ppal"
                  target="_blank"
                >
                  {seePrototype} <ArrowRightShort size={24} />
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="next-project">
            <Col>
              <h3 className="title">{nextProject}</h3>
              <Link className="link" to="/gmp">
                Garantía Mercado Público
                <ArrowRightShort size={64} />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
