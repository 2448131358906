import React, { useEffect } from "react";
import Navigation from "../Home/Navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRightShort } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";
import { useLanguage } from "../../LanguageContext";

export default function Getnet() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { language } = useLanguage();

  const homeText = language === "es" ? "Inicio" : "Home";
  const portfolioText = language === "es" ? "Portafolio" : "Case Studies";
  const overviewTitle = language === "es" ? "Resumen" : "Overview";
  const designProcess =
    language === "es" ? "Proceso de diseño" : "Design process";
  const researchTitle = language === "es" ? "1. Investigación" : "1. Research";
  const prototypingTitle =
    language === "es" ? "2. Prototipado" : "2. Prototyping";
  const testingTitle = language === "es" ? "3. Testeo" : "3. Testing";
  const developmentTitle =
    language === "es" ? "4. Desarrollo" : "4. Development";
  const nextProject = language === "es" ? "Siguiente proyecto" : "Next project";


  const headerDescription =
    language === "es"
      ? "Una experiencia de onboarding digital, con el apoyo y respaldo del Banco Santander"
      : "A digital onboarding experience, with Santander Bank support and backing";

  const overviewText1 =
    language === "es"
      ? "Los procesos de OnBoarding suelen ser un poco tediosos, sobre todo cuando hay datos sensibles y mucha información que introducir."
      : "OnBoarding processes are often a bit tedious, especially when there is sensitive data and a lot of information to enter.";

  const overviewText2 =
    language === "es"
      ? "El desafío de este proyecto era promover el nuevo sistema de pago que ofrecía el banco y dar de alta al mayor número posible de clientes."
      : "The challenge in this project was to promote the new payment system offered by the bank and to register as many customers as possible.";

  const researchText =
    language === "es"
      ? "Trabajé de la mano con el Product Owner para comprender los requerimientos, los datos que necesitábamos recopilar y la plataforma en donde estaría el formulario."
      : "I worked with the Product Owner to understand the business requirements, the data we needed to collect and the platform on which the form would be placed.";

  const prototypingText =
    language === "es"
      ? "Se diseñó un formulario con múltiples pasos en el que recogeríamos todos los datos, desde la dirección de la empresa hasta los documentos necesarios para la contratación."
      : "A form was designed with multiple steps where we would collect all the data, from the business address to the documents required for the contracting.";

  const testingText =
    language === "es"
      ? "Debido a la confidencialidad del proyecto en ese momento, las pruebas fueron internas con miembros del equipo, tanto clientes como no clientes del banco."
      : "Due to the confidentiality of the project at that time, the tests were internal, with team members, both customers and non-customers of the bank.";

  const developmentText =
    language === "es"
      ? "El formulario se incluiría en el portal privado del cliente y fue desarrollado en Angular, siguiendo las pautas de los componentes ya definidos por el banco y respetando el sistema de diseño."
      : "The form would be included in the client's private portal and was developed in Angular, following the guidelines of the components already defined by the bank and respecting the design system.";

  return (
    <>
      <Navigation />
      <section id="project-header" className="color-change-4x">
        <Container fluid className="default-container">
          <Row className="hero-project justify-content-center">
            <Col className="col-default">
              <Row>
                <Col md={12}>
                  <div className="project--title">
                    <h1>Getnet</h1>
                    <h2>
                      {headerDescription}
                    </h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid className="default-container">
          <Row>
            <Col className="project-container">
              <div className="breadcrumb">
                <Link to="/" className="breadcrumb--item">
                  {homeText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <Link to="/#portfolio" className="breadcrumb--item">
                  {portfolioText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <div className="breadcrumb--item active">Getnet</div>
              </div>

              <div className="overview">
                <h3 className="title">{overviewTitle}</h3>
                <p>
                  {overviewText1}
                </p>
                <p className="mb-5">
                  {overviewText2}
                </p>
              </div>
              <div className="img-container">
                <img className="w-100" src="./img/projects/getnet-01.png" />
                <img
                  className="w-100 mt-5"
                  src="./img/projects/getnet-02.png"
                />
              </div>

              <div className="overview pt-5">
                <h3 className="title">{designProcess}</h3>
                <h4>{researchTitle}</h4>
                <p>
                  {researchText}
                </p>

                <h4>{prototypingTitle}</h4>
                <p>
                  {prototypingText}
                </p>

                <h4>{testingTitle}</h4>
                <p>
                  {testingText}
                </p>

                <h4>{developmentTitle}</h4>
                <p className="mb-5 pb-5">
                  {developmentText}
                </p>
              </div>

              <div className="video-container">
                <video controls controlsList="nodownload">
                  <source
                    src="./img/projects/getnet-video.mp4"
                    type="video/mp4"
                  />
                  Your browser doesn't support this video element.
                </video>
              </div>
            </Col>
          </Row>

          <Row className="next-project">
            <Col>
              <h3 className="title">{nextProject}</h3>
              <Link className="link" to="/santander-parking">
                Parking App
                <ArrowRightShort size={64} />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
