import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import data from "../../data/index_en.json";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useLanguage } from "../../LanguageContext";

export default function WhatIDo() {
  const { language } = useLanguage();

  const title = language === "es" ? "Acerca de mi" : "About me";
  const paragraph1 =
    language === "es" ? (
      <>
        Me manejo cómodamente con herramientas de diseño y desarrollo. Para el
        diseño, uso la suite de Adobe y herramientas de prototipado como{" "}
        <span>Figma y Sketch</span>.
      </>
    ) : (
      <>
        I feel comfortable with design and development tools. For design, I use
        the Adobe suite and prototyping tools like <span>Figma and Sketch</span>
        .
      </>
    );

  const paragraph2 =
    language === "es" ? (
      <>
        Cuando se trata de dar vida a esos diseños, lo hago con{" "}
        <span>HTML5, CSS3 y preprocesadores de CSS como Sass</span>. Tengo
        experiencia creando interfaces y aplicativos con frameworks como{" "}
        <span>Bootstrap, Angular, React y Vue</span>, y también construyendo
        librerías de diseño en <span>Storybook</span>, trabajando tanto con{" "}
        <span>React</span> como con <span>React Native Paper</span>.
      </>
    ) : (
      <>
        When it comes to bringing those designs to life, I do it with{" "}
        <span>HTML5, CSS3, and CSS preprocessors like Sass</span>. I have
        experience creating engaging solutions with frameworks like{" "}
        <span>Bootstrap, Angular, React and Vue</span>, and also building design
        libraries in <span>Storybook</span>, working with both{" "}
        <span>React</span> and <span>React Native Paper</span>.
      </>
    );

  return (
    <section id="about">
      <Container className="default-container">
        <Row className="about">
          <Col md={12}>
            <h2 className="title">{title}</h2>
          </Col>
          <Col md={12}>
            <p className="mb-5">{paragraph1}</p>
            <p className="mb-5">{paragraph2}</p>
          </Col>
          <Col md={12}>
            <div className="tools-container">
              {data.tools.map((tool, index) => (
                <OverlayTrigger
                  key={index}
                  placement="bottom"
                  overlay={<Tooltip id={tool.name}>{tool.name}</Tooltip>}
                >
                  <div key={index} className="tool">
                    <img
                      src={tool.img}
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                    />
                  </div>
                </OverlayTrigger>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
