import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ArrowRightShort } from "react-bootstrap-icons";
import { CheckCircle } from "react-bootstrap-icons";
import { EmojiFrown } from "react-bootstrap-icons";
import { useLanguage } from "../../LanguageContext";

export default function Contact() {
  const { language } = useLanguage();
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isSendingForm, setIsSendingForm] = useState(false);

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSendingForm(true);

    try {
      const response = await fetch(
        "https://unatalandrea.com/ftools/process.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            form_tools_form_id: "1",
            name,
            email,
            message,
          }),
        }
      );

      if (response.ok) {
        setIsSendingForm(false);
        setFormSubmitted(true);
        setName("");
        setEmail("");
        setMessage("");
      } else {
        setIsSendingForm(false);
        setFormError(true);
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
    }
  };

  const title = language === "es" ? "Contáctame" : "Get in touch";
  const paragraph1 =
    language === "es"
      ? "Si quieres conversar sobre alguno de tus proyectos puedes escribirme a"
      : "If you want to chat about a project — you can email me on";
  const paragraph2 =
    language === "es"
      ? "o enviarme un mensaje a través del formulario."
      : "or send me a message from the form below.";
  const paragraph3 =
    language === "es"
      ? "Estoy aquí para asistirte en la creación o mejora de tu sitio web, el establecimiento de sistemas de diseño robustos, además de diseñar y optimizar productos y aplicaciones."
      : "I'm here to assist you in creating or improving your website, establishing robust design systems, as well as designing and optimizing products and applications.";
  const formName = language === "es" ? "Nombre" : "Name";
  const formEmail = language === "es" ? "Correo electrónico" : "Email";
  const formContact = language === "es" ? "¿Cómo puedo ayudarte?" : "How can I help you?";
  const formButton = language === "es" ? "Enviar mensaje" : "Send Message";
  const formSending = language === "es" ? "Enviando..." : "Sending...";
  const formSended = language === "es" ? "¡Gracias por escribirme!" : "Thank you for getting in touch!";
  const formSended2 = language === "es" ? "Te contactaré muy pronto. Que tengas un excelente día." : "I will get back to you shortly. Have a great day!";
  const formSendedError = language === "es" ? "Hubo un problema al enviar tu mensaje." : "Something went wrong when sending your message.";
  const formSendedError2 = language === "es" ? "Por favor intenta nuevamente." : "Please try again.";
  return (
    <section id="contact">
      <Container className="default-container">
        <Row className="contact">
          <Col md={12}>
            <h2 className="title">{title}</h2>
            <p className="mb-5">
              {paragraph1}{" "}
              <a className="link" href="mailto:hola@unatalandrea.com">
                hola@unatalandrea.com
              </a>{" "}
              {paragraph2}
            </p>
            <p className="mb-5">{paragraph3}</p>
          </Col>
          <Col md={5}>
            <Form onSubmit={handleSubmit}>
              <Form.Control
                onChange={(e) => {
                  setName(e.target.value);
                }}
                type="text"
                placeholder={formName}
                required
                className={`mb-3 ${name && "has-data"}`}
                name="name"
                value={name}
              />
              <Form.Control
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="email"
                placeholder={formEmail}
                required
                className={`mb-3 ${email && isEmailValid(email) && "has-data"}`}
                name="email"
                value={email}
              />
              <Form.Control
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                as="textarea"
                placeholder={formContact}
                required
                rows={3}
                className={`mb-4 ${message && "has-data"}`}
                name="message"
                value={message}
              />
              <Button
                disabled={isSendingForm}
                className="btn-ppal"
                type="submit"
              >
                {isSendingForm ? formSending : formButton}
                {!isSendingForm && <ArrowRightShort size={24} />}
              </Button>
            </Form>
          </Col>
          <Col md={12}>
            {formSubmitted && (
              <div className="form-submited-text">
                <div className="heading">
                  <CheckCircle size={32} className="me-3" />
                  <p>{formSended}</p>
                </div>
                <p className="ps-5">
                  {formSended2}
                </p>
              </div>
            )}
            {formError && (
              <div className="form-submited-text">
                <div className="heading">
                  <EmojiFrown size={32} className="me-3" />
                  <p>{formSendedError}</p>
                </div>
                <p className="ps-5">{formSendedError2}</p>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}
