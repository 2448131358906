import React, { useEffect } from "react";
import Navigation from "../Home/Navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRightShort } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";
import { useLanguage } from "../../LanguageContext";

export default function Edifito() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { language } = useLanguage();

  const homeText = language === "es" ? "Inicio" : "Home";
  const portfolioText = language === "es" ? "Portafolio" : "Case Studies";
  const overviewTitle = language === "es" ? "Resumen" : "Overview";
  const designProcess =
    language === "es" ? "Proceso de diseño" : "Design process";
  const researchTitle = language === "es" ? "1. Investigación" : "1. Research";
  const prototypingTitle =
    language === "es" ? "2. Prototipado" : "2. Prototyping";
  const testingTitle = language === "es" ? "3. Testeo" : "3. Testing";
  const developmentTitle =
    language === "es" ? "4. Desarrollo" : "4. Development";
  const nextProject = language === "es" ? "Siguiente proyecto" : "Next project";

  const headerDescription =
    language === "es"
      ? "Plataforma online para gestión de edificios y condominios"
      : "Online software for the real state management";

  const overviewText1 =
    language === "es"
      ? "Aunque el principal objetivo de la empresa es mejorar y digitalizar el trabajo de los administradores de edificios y condominios, queríamos incluir a los residentes en el proceso y facilitar la comunicación entre ellos y el comité de administración."
      : "Although the main focus of the company is to improve and digitize the work of building and condos managers, we wanted to include the residents in the process and facilitate communication between them and the management committee.";

  const overviewText2 =
    language === "es"
      ? "Así que decidimos crear una aplicación móvil que les ayudara a realizar todas sus tareas de forma más rápida y cómoda."
      : "So we was decided to create a mobile application that would help them perform all their tasks more quickly and comfortably.";

  const researchText =
    language === "es"
      ? "Se realizaron entrevistas con los futuros usuarios de la app para recopilar la información necesaria y desarrollar un producto acorde con sus necesidades."
      : "Interviews were conducted with the future users of the app to gather the necessary information and develop a product according to their needs.";

  const prototypingText =
    language === "es"
      ? "A lo largo del proceso se desarrollaron prototipos de baja, media y alta fidelidad para validar la solución propuesta."
      : "Low, medium and high fidelity prototypes were developed throughout the process to validate the proposed solution.";

  const testingText =
    language === "es"
      ? "Los prototipos creados se validaron con pruebas de usabilidad y tree tests para organizar los elementos del menú de la mejor manera posible."
      : "The prototypes created were validated with usability tests and tree tests to organize the items in the menu in the best possible way.";

  const developmentText =
    language === "es"
      ? "Por último, el producto se desarrolló en Android Studio, donde pude apoyar con la parte visual para mantenerlo acorde al prototipo."
      : "Finally, the product was developed in Android Studio, where I was able to help with the styling to keep it in line with the prototype.";

  return (
    <>
      <Navigation />
      <section id="project-header" className="color-change-4x">
        <Container fluid className="default-container">
          <Row className="hero-project justify-content-center">
            <Col className="col-default">
              <Row>
                <Col md={12}>
                  <div className="project--title">
                    <h1>Edifito</h1>
                    <h2>{headerDescription}</h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid className="default-container">
          <Row>
            <Col className="project-container">
              <div className="breadcrumb">
                <Link to="/" className="breadcrumb--item">
                  {homeText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <Link to="/#portfolio" className="breadcrumb--item">
                  {portfolioText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <div className="breadcrumb--item active">Edifito</div>
              </div>

              <div className="overview">
                <h3 className="title">{overviewTitle}</h3>
                <p>
                  {overviewText1}
                </p>
                <p className="mb-5">
                  {overviewText2}
                </p>
              </div>
              <div className="img-container">
                <img
                  className="w-100"
                  src="./img/projects/edifito-01.png"
                />
                <img
                  className="w-100 mt-5"
                  src="./img/projects/edifito-02.jpg"
                />
                 <img
                  className="w-100 mt-5"
                  src="./img/projects/edifito-03.png"
                />
              </div>

              <div className="overview pt-5">
                <h3 className="title">{designProcess}</h3>
                <h4>{researchTitle}</h4>
                <p>
                  {researchText}
                </p>

                <h4>{prototypingTitle}</h4>
                <p>
                  {prototypingText}
                </p>

                <h4>{testingTitle}</h4>
                <p>
                  {testingText}
                </p>

                <h4>{developmentTitle}</h4>
                <p>
                  {developmentText}
                </p>
              </div>
            </Col>
          </Row>

          <Row className="next-project">
            <Col>
              <h3 className="title">{nextProject}</h3>
              <Link className="link" to="/getnet">
                Getnet
                <ArrowRightShort size={64} />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
