import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Linkedin } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer color-change-4x">
      <Container fluid className="footer--container">
        <Row>
          <Col>
            <div>
              <Link to="/#home">
                <img
                  alt="Andrea"
                  src="/img/logo.svg"
                  width="34"
                  height="34"
                  className="logo d-inline-block"
                />
              </Link>
              <span>UnaTalAndrea.com &copy; 2024</span>
            </div>
            <Link className="linkedin" to="https://www.linkedin.com/in/unatalandrea/" target="_blank">
              <Linkedin size={32} />
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
