import React, { useEffect } from "react";
import Navigation from "../Home/Navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRightShort } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";
import { useLanguage } from "../../LanguageContext";

export default function GMP() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { language } = useLanguage();

  const homeText = language === "es" ? "Inicio" : "Home";
  const portfolioText = language === "es" ? "Portafolio" : "Case Studies";
  const overviewTitle = language === "es" ? "Resumen" : "Overview";
  const designProcess =
    language === "es" ? "Proceso de diseño" : "Design process";
  const researchTitle = language === "es" ? "1. Investigación" : "1. Research";
  const prototypingTitle =
    language === "es" ? "2. Prototipado" : "2. Prototyping";
  const testingTitle = language === "es" ? "3. Testeo" : "3. Testing";
  const developmentTitle =
    language === "es" ? "4. Desarrollo" : "4. Development";
  const nextProject = language === "es" ? "Siguiente proyecto" : "Next project";

  const title =
    language === "es"
      ? "Garantía Mercado Público de Maxxa"
      : "Garantía Mercado Público by Maxxa";
  const headerDescription =
    language === "es"
      ? "Sitio web para solicitar las garantías requeridas para licitar en el Mercado Público"
      : "Website for requesting the guarantees required for bids in the Public Market";

  const overviewText1 =
    language === "es"
      ? "Maxxa es una Fintech especializada en brindar oportunidades financieras a pequeñas y medianas empresas, con foco en aquellas que licitan en el mercado público. Dentro del proceso de licitación existen ciertos requisitos, entre los que se encuentran las garantías de fiel cumplimiento y seriedad de la oferta."
      : "Maxxa is a Fintech that specializes in providing financial opportunities to small and medium-sized companies, with a focus on those that bid in the public market. Within the bidding process there are certain requirements, among which are the guarantees of faithful compliance and seriousness of the offer.";

  const overviewText2 =
    language === "es"
      ? "El objetivo era crear un sitio web donde estas empresas pudieran solicitar y obtener esas garantías 100% en línea y sin papeleos innecesarios."
      : "The aim was to create a website where these companies could request and obtain those guarantees 100% online and without unnecessary paperwork.";

  const researchText =
    language === "es"
      ? "Mi mayor reto en este proyecto fue entender cómo funcionaba el proceso y todo lo que tenían que hacer las empresas para conseguir los recaudos necesarios para entrar en una licitación en el mercado público."
      : "My biggest challenge in this project was to understand how the process worked and everything the companies had to do to get all the necessary steps to enter a bidding process in the public market.";

  const prototypingText =
    language === "es"
      ? "Tras las entrevistas y la recopilación de información con clientes y ejecutivos, comenzamos con el proceso de creación de prototipos, incluyendo desde ese momento al equipo de desarrollo."
      : "After interviews and information gathering with clients and executives, we started with the prototyping process, involving the development team from this point.";

  const testingText =
    language === "es"
      ? "Se realizaron pruebas de usabilidad con usuarios finales, tanto ejecutivos como clientes, para confirmar que la solución se ajustaba a sus necesidades."
      : "Usability testing sessions were conducted with end users, both executives and customers, to confirm that the solution was suitable for their needs.";

  const developmentText =
    language === "es"
      ? "La plataforma fue realizada por el equipo de desarrollo en Phyton, donde pude apoyarlos con la estructura HTML y SCSS con Bootstrap."
      : "The platform was made by the development team in Phyton, where I was able to support the HTML structure and SCSS with Bootstrap.";
  return (
    <>
      <Navigation />
      <section id="project-header" className="color-change-4x">
        <Container fluid className="default-container">
          <Row className="hero-project justify-content-center">
            <Col className="col-default">
              <Row>
                <Col md={12}>
                  <div className="project--title">
                    <h1>{title}</h1>
                    <h2>{headerDescription}</h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid className="default-container">
          <Row>
            <Col className="project-container">
              <div className="breadcrumb">
                <Link to="/" className="breadcrumb--item">
                  {homeText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <Link to="/#portfolio" className="breadcrumb--item">
                  {portfolioText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <div className="breadcrumb--item active">
                  Garantía Mercado Público
                </div>
              </div>

              <div className="overview">
                <h3 className="title">{overviewTitle}</h3>
                <p>{overviewText1}</p>
                <p className="mb-5">{overviewText2}</p>
              </div>
              <div className="img-container">
                <img className="w-100" src="./img/projects/gmp-01.png" />
                <img className="w-100 mt-5" src="./img/projects/gmp-02.png" />
              </div>

              <div className="overview pt-5">
                <h3 className="title">{designProcess}</h3>
                <h4>{researchTitle}</h4>
                <p>{researchText}</p>

                <h4>{prototypingTitle}</h4>
                <p>{prototypingText}</p>

                <h4>{testingTitle}</h4>
                <p>{testingText}</p>

                <h4>{developmentTitle}</h4>
                <p>{developmentText}</p>
              </div>
            </Col>
          </Row>

          <Row className="next-project">
            <Col>
              <h3 className="title">{nextProject}</h3>
              <Link className="link" to="/tallo-taller">
                TalloTaller.cl
                <ArrowRightShort size={64} />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
