import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TypeAnimation } from "react-type-animation";
import { useLanguage } from "../../LanguageContext";

export default function HeroSection() {
  const { language } = useLanguage();
  const title =
    language === "es" ? "Hola mundo! Soy Andrea" : "Hello world! I'm Andrea";
  const description =
    language === "es"
      ? "Fusiono diseño UX y desarrollo UI/Front-end para crear interfaces atractivas y funcionales. Priorizo la usabilidad y el código eficiente, buscando siempre armonía entre estética y funcionalidad."
      : "I combine UX design with UI/front-end development to create interfaces that are both applealing and functional. I prioritise usability and efficient code, always seeking harmony between aesthetics and functionality.";

  return (
    <section id="home" className="color-change-4x">
      <Container className="default-container">
        <Row className="hero justify-content-center">
          <Col className="col-default">
            <Row>
              <Col md={12}>
                <p className="hero--pre-title">{title}</p>

                <div className="hero--title">
                  <TypeAnimation
                    sequence={[
                      "Product Designer",
                      3000,
                      ,
                      "Visual Designer",
                      3000,
                      ,
                      "Front-End Designer",
                      3000,
                      ,
                    ]}
                    className="typing"
                    repeat={Infinity}
                  />
                </div>
              </Col>
              <Col className="hero--col">
                <p className="hero--description">{description}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
