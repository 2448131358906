import React, { useEffect } from "react";
import Navigation from "../Home/Navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRightShort } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Footer from "../Home/Footer";
import { useLanguage } from "../../LanguageContext";

export default function SantanderParking() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { language } = useLanguage();

  const homeText = language === "es" ? "Inicio" : "Home";
  const portfolioText = language === "es" ? "Portafolio" : "Case Studies";
  const overviewTitle = language === "es" ? "Resumen" : "Overview";
  const designProcess =
    language === "es" ? "Proceso de diseño" : "Design process";
  const researchTitle = language === "es" ? "1. Investigación" : "1. Research";
  const prototypingTitle =
    language === "es" ? "2. Prototipado" : "2. Prototyping";
  const testingTitle = language === "es" ? "3. Testeo" : "3. Testing";
  const developmentTitle =
    language === "es" ? "4. Desarrollo" : "4. Development";
  const nextProject = language === "es" ? "Siguiente proyecto" : "Next project";
  const seePrototype = language === "es" ? "Ver prototipo" : "See prototype";
  const nextProjectName =
    language === "es" ? "Sistema de Gestión de Pagos" : "Payment System";

  const headerDescription =
    language === "es"
      ? "Aplicación móvil para ejecutivos y empleados del Banco Santander"
      : "Mobile application for Santander Bank executives and employees";

  const overviewText1 =
    language === "es"
      ? "Encontrar estacionamiento en edificios con mucho tráfico de vehículos a menudo se convierte en una tarea difícil, especialmente cuando no se cuenta con puestos fijos."
      : "Many times getting a parking space in buildings where there is a high flow of cars can be a headache, even more so when there are no fixed parking spaces.";

  const overviewText2 =
    language === "es"
      ? "En esta oportunidad queríamos facilitar la gestión de reservas de puestos de estacionamiento a los directivos y empleados del banco, por lo que decidimos crear una app para este fin, donde pudieran gestionar las reservas diarias y elegir el mejor puesto según sus necesidades."
      : "In this opportunity we wanted to facilitate the management of parking space reservations for the bank's executives and employees, so we decided to create an app for this purpose, where they could manage daily reservations and choose the best parking space according to their needs.";

  const researchText =
    language === "es"
      ? "Recopilamos información mediante entrevistas para entender el problema y saber cómo abordarlo."
      : "We gathered information through interviews in order to understand the problem and know how to address it.";

  const prototypingText1 =
    language === "es"
      ? "Se creó un"
      : "A";

  const prototypeLinkText =
    language === "es"
      ? "prototipo de alta fidelidad"
      : "high-fidelity prototype";

      const prototypingText2 =
    language === "es"
      ? "en Sketch, respetando el sistema de diseño proporcionado por el banco."
      : "was created on in Sketch, respecting the bank's design system.";

  const testingText =
    language === "es"
      ? "La validación correspondiente se realizó con pruebas de usabilidad con el grupo de usuarios involucrados."
      : "The corresponding validation was carried out with usability tests with the group of users involved.";

  const developmentText =
    language === "es"
      ? "La app se desarrolló con Ionic y Angular, para ofrecer una solución que funcionara en todos los dispositivos móviles."
      : "The app was developed with Ionic and Angular, to offer a solution that would work on all mobile devices.";

  return (
    <>
      <Navigation />
      <section id="project-header" className="color-change-4x">
        <Container fluid className="default-container">
          <Row className="hero-project justify-content-center">
            <Col className="col-default">
              <Row>
                <Col md={12}>
                  <div className="project--title">
                    <h1>Parking App</h1>
                    <h2>{headerDescription}</h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid className="default-container">
          <Row>
            <Col className="project-container">
              <div className="breadcrumb">
                <Link to="/" className="breadcrumb--item">
                  {homeText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <Link to="/#portfolio" className="breadcrumb--item">
                  {portfolioText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <div className="breadcrumb--item active">Parking App</div>
              </div>

              <div className="overview">
                <h3 className="title">{overviewTitle}</h3>
                <p>{overviewText1}</p>
                <p className="mb-5">{overviewText2}</p>
              </div>
              <div className="img-container">
                <img className="w-100" src="./img/projects/parkingapp-01.png" />
                <img
                  className="w-100 mt-5"
                  src="./img/projects/parkingapp-02.png"
                />
              </div>

              <div className="overview pt-5">
                <h3 className="title">{designProcess}</h3>
                <h4>{researchTitle}</h4>
                <p>{researchText}</p>

                <h4>{prototypingTitle}</h4>
                <p>
                  {prototypingText1}{" "}
                  <a
                    href="https://andrea272350.invisionapp.com/console/share/2EY3BNXKPVH"
                    target="_blank"
                    className="link"
                  >
                    {prototypeLinkText}{" "}
                  </a>{" "}
                  {prototypingText2}
                </p>

                <h4>{testingTitle}</h4>
                <p>
                  {testingText}
                </p>

                <h4>{developmentTitle}</h4>
                <p className="mb-5 pb-5">
                  {developmentText}
                </p>
              </div>

              <div className="button-container">
                <Button
                  href="https://andrea272350.invisionapp.com/console/share/2EY3BNXKPVH"
                  className="btn-ppal"
                  target="_blank"
                >
                  {seePrototype} <ArrowRightShort size={24} />
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="next-project">
            <Col>
              <h3 className="title">{nextProject}</h3>
              <Link className="link" to="/payment-system">
                {nextProjectName}
                <ArrowRightShort size={64} />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
