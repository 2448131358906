import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import data_en from "../../data/index_en.json";
import data_es from "../../data/index_es.json";
import { Link } from "react-router-dom";
import { useLanguage } from "../../LanguageContext";

export default function Portfolio() {
  const { language } = useLanguage();

  const title = language === "es" ? "Portafolio" : "Case Studies";
  const subtitle =
    language === "es"
      ? "Algunos de los proyectos en los que he participado"
      : "Some of the projects I have participated in";

      const data = language === "es" ? data_es : data_en
  return (
    <section id="portfolio">
      <Container className="default-container">
        <Row className="portfolio">
          <Col md={12}>
            <h2 className="title">{title}</h2>
            <p>{subtitle}</p>
          </Col>
          {data.projects.map((project, index) => (
            <Col className="project" key={index} lg={project.featured ? 12 : 6}>
              <Link to={project.path}>
                <img className="project--img" src={project.img} />
                <div className="project--client">{project.client}</div>
                <div className="project--name">{project.name}</div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
