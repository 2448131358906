import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Homescreen";
import AVLibrary from "./Pages/Projects/AVLibrary";
import LatamAirlines from "./Pages/Projects/LatamAirlines";
import BodegaLibre from "./Pages/Projects/BodegaLibre";
import Edifito from "./Pages/Projects/Edifito";
import Getnet from "./Pages/Projects/Getnet";
import SantanderParking from "./Pages/Projects/SantanderParking";
import PaymentSystem from "./Pages/Projects/PaymentSystem";
import GMP from "./Pages/Projects/GMP";
import TalloTaller from "./Pages/Projects/TalloTaller";
import { useTheme } from "./ThemeContext";
import { LanguageProvider } from "./LanguageContext";

const App = () => {
  const { theme } = useTheme();
  return (
    <LanguageProvider>
      <Router scrollReset>
        <div className={`app ${theme}`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/av-ui-library" element={<AVLibrary />} />
            <Route path="/latam-airlines-app" element={<LatamAirlines />} />
            <Route path="/bodegalibre" element={<BodegaLibre />} />
            <Route path="/edifito" element={<Edifito />} />
            <Route path="/getnet" element={<Getnet />} />
            <Route path="/santander-parking" element={<SantanderParking />} />
            <Route path="/payment-system" element={<PaymentSystem />} />
            <Route path="/gmp" element={<GMP />} />
            <Route path="/tallo-taller" element={<TalloTaller />} />
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
};

export default App;
