import React, { useEffect } from "react";
import Navigation from "../Home/Navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRightShort } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Footer from "../Home/Footer";
import { useLanguage } from "../../LanguageContext";

export default function TalloTaller() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { language } = useLanguage();

  const homeText = language === "es" ? "Inicio" : "Home";
  const portfolioText = language === "es" ? "Portafolio" : "Case Studies";
  const overviewTitle = language === "es" ? "Resumen" : "Overview";
  const designProcess =
    language === "es" ? "Proceso de diseño" : "Design process";
  const researchTitle = language === "es" ? "1. Investigación" : "1. Research";
  const prototypingTitle =
    language === "es" ? "2. Prototipado" : "2. Prototyping";
  const testingTitle = language === "es" ? "3. Testeo" : "3. Testing";
  const developmentTitle =
    language === "es" ? "4. Desarrollo" : "4. Development";
  const nextProject = language === "es" ? "Siguiente proyecto" : "Next project";
  const seeWebsite = language === "es" ? "Ver sitio web" : "See website";
  const headerDescription =
    language === "es"
      ? "Sitio web para una empresa que diseña y construye jardines que crean una conexión entre los usuarios y la naturaleza"
      : "Website for a company that designs and builds gardens that create a connection between users and nature";

  const overviewText1 =
    language === "es"
      ? "Tallo Taller es una empresa dedicada al paisajismo. Evalúan cada proyecto en base a las necesidades de sus clientes y desarrollan un concepto que es el pilar de cada propuesta, donde arquitectura y paisaje se unen para dar paso a nuevas formas, ambientes y experiencias."
      : "Tallo Taller is a company dedicated to landscaping. They evaluate each project based on the needs of their clients and develop a concept that is the pillar of each proposal, where architecture and landscape come together to give way to new forms, environments and experiences.";

  const overviewText2 =
    language === "es"
      ? "Me contactaron porque querían crear un sitio web que reflejara la pasión y dedicación que ponen en cada uno de sus proyectos."
      : "They contacted me because they wanted to create a website that would reflect the passion and dedication they put into each of their projects.";

  const researchText =
    language === "es"
      ? "En este proyecto me centré en el benchmarking para conocer mejor el negocio. Nuestros clientes tenían toda la información que querían plasmar en su sitio web."
      : "In this project my focus was on benchmarking to have a better understanding of the business. Our clients had all the information they wanted to capture on their website.";

  const prototypingText =
    language === "es"
      ? "El diseño se realizó en Sketch, mostrando todas las secciones del sitio web y la diagramación de cada página."
      : "The design was made in sketch, showing all the sections of the website and the layout of each one of them.";

  const testingText =
    language === "es"
      ? "Una vez alcanzada la identidad visual aprobada por los clientes, validamos la propuesta creada con pruebas de usabilidad para alcanzar organización ópima de la página."
      : "Once we reached the visual identity approved by the clients, we validated the proposal created with usability tests to reach the best organization of the page.";

  const developmentText =
    language === "es"
      ? "El sitio web se desarrolló en WordPress, para facilitar a los clientes el mantenimiento y la carga de nueva información."
      : "The website was developed in WordPress, to make it easier for the clients to maintain and upload new information.";
  return (
    <>
      <Navigation />
      <section id="project-header" className="color-change-4x">
        <Container fluid className="default-container">
          <Row className="hero-project justify-content-center">
            <Col className="col-default">
              <Row>
                <Col md={12}>
                  <div className="project--title">
                    <h1>TalloTaller.cl</h1>
                    <h2>{headerDescription}</h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid className="default-container">
          <Row>
            <Col className="project-container">
              <div className="breadcrumb">
                <Link to="/" className="breadcrumb--item">
                  {homeText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <Link to="/#portfolio" className="breadcrumb--item">
                  {portfolioText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <div className="breadcrumb--item active">TalloTaller.cl</div>
              </div>

              <div className="overview">
                <h3 className="title">{overviewTitle}</h3>
                <p>{overviewText1}</p>
                <p className="mb-5">{overviewText2}</p>
              </div>
              <div className="img-container">
                <img
                  className="w-100"
                  src="./img/projects/tallotaller-01.png"
                />
                <img
                  className="w-100 mt-5"
                  src="./img/projects/tallotaller-02.png"
                />
              </div>

              <div className="overview pt-5">
                <h3 className="title">{designProcess}</h3>
                <h4>{researchTitle}</h4>
                <p>{researchText}</p>

                <h4>{prototypingTitle}</h4>
                <p>{prototypingText}</p>

                <h4>{testingTitle}</h4>
                <p>{testingText}</p>

                <h4>{developmentTitle}</h4>
                <p>{developmentText}</p>
              </div>

              <div className="button-container">
                <Button
                  href="https://tallotaller.cl/"
                  className="btn-ppal"
                  target="_blank"
                >
                  {seeWebsite} <ArrowRightShort size={24} />
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="next-project">
            <Col>
              <h3 className="title">{nextProject}</h3>
              <Link className="link" to="/av-ui-library">
                AV UI Library
                <ArrowRightShort size={64} />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
