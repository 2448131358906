import React, { useEffect } from "react";
import Navigation from "../Home/Navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRightShort } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Footer from "../Home/Footer";
import { useLanguage } from "../../LanguageContext";

export default function BodegaLibre() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { language } = useLanguage();

  const homeText = language === "es" ? "Inicio" : "Home";
  const portfolioText = language === "es" ? "Portafolio" : "Case Studies";
  const overviewTitle = language === "es" ? "Resumen" : "Overview";
  const designProcess =
    language === "es" ? "Proceso de diseño" : "Design process";
  const researchTitle = language === "es" ? "1. Investigación" : "1. Research";
  const prototypingTitle =
    language === "es" ? "2. Prototipado" : "2. Prototyping";
  const testingTitle = language === "es" ? "3. Testeo" : "3. Testing";
  const developmentTitle =
    language === "es" ? "4. Desarrollo" : "4. Development";
  const nextProject = language === "es" ? "Siguiente proyecto" : "Next project";

  const headerDescription =
    language === "es"
      ? "Página web de compraventa de productos y servicios para la industria minera"
      : "Website for the purchase and sale of products and services for the mining industry";

  const overviewText1 =
    language === "es"
      ? "El cliente se puso en contacto con nosotros con la idea de crear un sitio web que pusiera en contacto a compradores y vendedores de productos y servicios para la minería, ya que existen pocas plataformas orientadas a este sector."
      : "The client contacted us with the idea of a website that could connect buyers and sellers of mining products and services, as there are few platforms oriented to this industry.";

  const overviewText2 =
    language === "es"
      ? "Una vez comprendidas las necesidades y recopilada la información, presentamos una propuesta con foco en la usabilidad para distintos tipos de usuarios."
      : "Once we understood the needs and gathered the information, we presented a proposal emphasizing usability for different types of users.";

  const researchText =
    language === "es"
      ? "En este proyecto trabajé mano a mano con el cliente, ya que tenía una idea muy clara de cómo debía ser su plataforma."
      : "In this project I worked hand in hand with the client, since he had a very clear idea of how his platform should be.";

  const prototypingText =
    language === "es"
      ? "Se desarrollaron prototipos a mano de baja fidelidad para maquetear las pantallas y tener una mejor visión de los componentes necesarios."
      : "Low-fidelity mockups were developed by hand to assemble the screens and get a better view of the required components.";

  const testingText =
    language === "es"
      ? "Los prototipos creados se validaron con pruebas de usabilidad y se trabajó con el equipo de desarrollo para ver la mejor forma de implementarlo."
      : "The prototypes created were validated with usability tests and worked with the development team to see the best way to implement it.";

  const developmentText =
    language === "es"
      ? "El sitio web se desarrolló en Vue, con variables de estilo globales en SCSS para facilitar cualquier cambio visual."
      : "The website was developed in Vue, with global style variables in SCSS for easier visual changes.";
  return (
    <>
      <Navigation />
      <section id="project-header" className="color-change-4x">
        <Container fluid className="default-container">
          <Row className="hero-project justify-content-center">
            <Col className="col-default">
              <Row>
                <Col md={12}>
                  <div className="project--title">
                    <h1>BodegaLibre.cl</h1>
                    <h2>{headerDescription}</h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid className="default-container">
          <Row>
            <Col className="project-container">
              <div className="breadcrumb">
                <Link to="/" className="breadcrumb--item">
                  {homeText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <Link to="/#portfolio" className="breadcrumb--item">
                  {portfolioText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <div className="breadcrumb--item active">BodegaLibre.cl</div>
              </div>

              <div className="overview">
                <h3 className="title">{overviewTitle}</h3>
                <p>{overviewText1}</p>
                <p className="mb-5">{overviewText2}</p>
              </div>
              <div className="img-container">
                <img
                  className="w-100"
                  src="./img/projects/bodegalibre-01.png"
                />
                <img
                  className="w-100 mt-5"
                  src="./img/projects/bodegalibre-02.png"
                />
              </div>

              <div className="overview pt-5">
                <h3 className="title">{designProcess}</h3>
                <h4>{researchTitle}</h4>
                <p>{researchText}</p>

                <h4>{prototypingTitle}</h4>
                <p>{prototypingText}</p>

                <h4>{testingTitle}</h4>
                <p>{testingText}</p>

                <h4>{developmentTitle}</h4>
                <p>{developmentText}</p>
              </div>
            </Col>
          </Row>

          <Row className="next-project">
            <Col>
              <h3 className="title">{nextProject}</h3>
              <Link className="link" to="/edifito">
                Edifito
                <ArrowRightShort size={64} />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
