import React, { useState } from "react";
import { useLanguage } from "../LanguageContext";

export default function FlagSwitcher() {
  const { language, toggleLanguage } = useLanguage();
  const isChecked = language === "en";

  return (
    <div
      className="flag-switch"
      data-first-lang="EN"
      data-second-lang="ES"
    >
      <input
        type="checkbox"
        id="check1"
        checked={isChecked}
        onChange={toggleLanguage}
      />
      <label htmlFor="check1"></label>
    </div>
  );
}