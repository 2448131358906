import React, { useEffect } from "react";
import Navigation from "../Home/Navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ArrowRightShort } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Footer from "../Home/Footer";
import { useLanguage } from "../../LanguageContext";

export default function LatamAirlines() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { language } = useLanguage();

  const homeText = language === "es" ? "Inicio" : "Home";
  const portfolioText = language === "es" ? "Portafolio" : "Case Studies";
  const overviewTitle = language === "es" ? "Resumen" : "Overview";
  const designProcess =
    language === "es" ? "Proceso de diseño" : "Design process";
  const researchTitle = language === "es" ? "1. Investigación" : "1. Research";
  const prototypingTitle =
    language === "es" ? "2. Prototipado" : "2. Prototyping";

  const nextProject = language === "es" ? "Siguiente proyecto" : "Next project";

  const seePrototype = language === "es" ? "Ver prototipo" : "See prototype";

  const headerDescription =
    language === "es"
      ? "La solución ecológica para los uniformes de los empleados de LATAM Airlines"
      : "The eco-friendly solution for LATAM Airlines employee uniforms";

  const overviewText1 =
    language === "es"
      ? "En un esfuerzo por promover la sostenibilidad y proporcionar comodidad a sus empleados, LATAM Airlines ha desarrollado la EcoApp Uniformes LATAM."
      : "In an effort to promote sustainability and pro vide convenience to its employees, LATAM Airlines has developed the LATAM Uniforms EcoApp.";

  const overviewText2 =
    language === "es"
      ? "Esta aplicación móvil está diseñada para que los empleados de LATAM Airlines puedan solicitar nuevos uniformes de forma rápida y eficiente, y al mismo tiempo contribuir con el medio ambiente. Con esta aplicación, los empleados pueden solicitar nuevos uniformes o reciclar sus uniformes actuales en buen estado, ayudando a reducir su impacto ambiental."
      : "This mobile application is designed for LATAM Airlines employees to quickly and efficiently request new uniforms while also contributing to the environment. With this app, employees can request new uniforms or recycle their current uniforms in good condition, helping to reduce their environmental impact.";

  const researchText =
    language === "es"
      ? "Se llevó a cabo un análisis de las necesidades y requisitos del cliente, LATAM Airlines, en colaboración con su equipo. Esta fase permitió comprender a fondo la visión y los objetivos del cliente."
      : "An analysis of the needs and requirements of the client, LATAM Airlines, was carried out in collaboration with their team. This stage allowed for a thorough understanding of the client's vision and goals.";

  const prototypingText1 =
    language === "es"
      ? "Con los componentes del sistema de diseño de LATAM Airlines, se creó un"
      : "Using the components and design system provided by LATAM Airlines, a";

  const prototypeLinkText =
    language === "es"
      ? "prototipo de alta fidelidad"
      : "high-fidelity prototype";

  const prototypingText2 =
    language === "es"
      ? "para mostrar la solución propuesta. Este prototipo nos permitió visualizar y comunicar eficazmente el diseño a todas las partes interesadas."
      : "was created in Figma to reflect the proposed solution. This prototype allowed the design to be effectively visualized and communicated to all stakeholders.";
  return (
    <>
      <Navigation />
      <section id="project-header" className="color-change-4x">
        <Container fluid className="default-container">
          <Row className="hero-project justify-content-center">
            <Col className="col-default">
              <Row>
                <Col md={12}>
                  <div className="project--title">
                    <h1>Latam Airlines</h1>
                    <h2>{headerDescription}</h2>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container fluid className="default-container">
          <Row>
            <Col className="project-container">
              <div className="breadcrumb">
                <Link to="/" className="breadcrumb--item">
                  {homeText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <Link to="/#portfolio" className="breadcrumb--item">
                  {portfolioText}
                </Link>
                <div className="breadcrumb--item">
                  <ArrowRightShort size={18} />
                </div>
                <div className="breadcrumb--item active">Latam Airlines</div>
              </div>

              <div className="overview">
                <h3 className="title">{overviewTitle}</h3>
                <p>{overviewText1}</p>
                <p className="mb-5">{overviewText2}</p>
              </div>
              <div className="img-container">
                <img className="w-100" src="./img/projects/latam-01.png" />
                <img className="w-100 mt-5" src="./img/projects/latam-02.png" />
              </div>

              <div className="overview pt-5">
                <h3 className="title">{designProcess}</h3>
                <h4>{researchTitle}</h4>
                <p>{researchText}</p>

                <h4>{prototypingTitle}</h4>
                <p className="mb-5">
                  {prototypingText1}{" "}
                  <a
                    href="https://www.figma.com/proto/tFHrV1waV9PdCzyZz6np3u/Latam-Uniform?page-id=0%3A1&type=design&node-id=3-13&viewport=1006%2C354%2C0.23&t=SqWrErla5twy2q1t-1&scaling=scale-down&starting-point-node-id=3%3A13&mode=design"
                    target="_blank"
                    className="link"
                  >
                    {prototypeLinkText}{" "}
                  </a>{" "}
                  {prototypingText2}
                </p>
              </div>

              <div className="button-container">
                <Button
                  href="https://www.figma.com/proto/tFHrV1waV9PdCzyZz6np3u/Latam-Uniform?page-id=0%3A1&type=design&node-id=3-13&viewport=1006%2C354%2C0.23&t=SqWrErla5twy2q1t-1&scaling=scale-down&starting-point-node-id=3%3A13&mode=design"
                  className="btn-ppal"
                  target="_blank"
                >
                  {seePrototype} <ArrowRightShort size={24} />
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="next-project">
            <Col>
              <h3 className="title">{nextProject}</h3>
              <Link className="link" to="/bodegalibre">
                BodegaLibre.cl
                <ArrowRightShort size={64} />
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
}
